import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueAxios from 'vue-axios';
import axios from 'axios';
import common from '../common';
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueAxios, axios);
Vue.use(BootstrapVue)
Vue.mixin(common);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  axios: axios,
  common: common,
  render: h => h(App)
}).$mount('#app')
