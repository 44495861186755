import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cancelamento-cartao',
    name: 'cancelamentocartao',
    component: () => import('../views/cancelamento-cartao.vue')
  },
  {
    path: '/consulta-especial',
    name: 'consultaespecial',
    component: () => import('../views/consulta-especial.vue')
  },
  {
    path: '/segunda-via',
    name: 'segundavia',
    component: () => import('../views/segunda-via.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
