export default {
    data() {
        return {

        }
    },
    methods: {
        async callApi(method, url, dataObj) {
            try {
                return await this.axios({
                    method: method,
                    url: process.env.VUE_APP_API_URL + url,
                    data: dataObj
                });
            } catch (e) {
                return e.response
            }
        }
    }
}